<template>
  <div class="m-map">
    <div id="js-container" class="map"></div>
  </div>
</template>

<script>
import AMap from 'AMap' // 引入高德地图

export default {
  name: "MapDrag",
  props: {
    storeList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      AMap: null,
    }
  },
  watch: {
    storeList: function () {
      this.initMap()
    }
  },
  mounted () {
    this.initMap()
  },
  methods: {
    // 实例化地图
    initMap () {
      let that = this;
      let mapConfig = {
        mapStyle: 'amap://styles/7a3b4897669cfcd770f626f4db50fd46',
      }
      var map = new AMap.Map('js-container', mapConfig)
      var infoWindow = new AMap.AdvancedInfoWindow({
        autoMove: true,
        offset: new AMap.Pixel(0, -30),
        placeSearch: false,
        asOrigin: false,
      });
      var storeList = that.storeList;
      for (const item of storeList) {
        var marker = new AMap.Marker({
          position: item.lnglat,
          map: map
        });
        marker.content = `<div style="background:#fff">
                            <div style="padding:20px;">
                              <div style="font-size:20px">${item.name}</div>
                              <div style="font-size:14px;margin-top:20px;">${item.address}</div>
                            </div>
                          </div>`;
        marker.on('click', markerClick);
        marker.emit('click', { target: marker });
      }
      function markerClick (e) {
        infoWindow.setContent(e.target.content);
        infoWindow.open(map, e.target.getPosition());
      }
      map.setFitView();
    }
  },

}
</script>

<style >
.m-map {
  min-width: 100%;
  min-height: 270px;
  position: relative;
}
.m-map .map {
  width: 100%;
  height: 100%;
}
</style>
